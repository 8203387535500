import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer className='footer'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='menu'>
            <ul>
              <li><Link to='/about/'>About</Link></li>
              <li><Link to='/contact/'>Contact</Link></li>
            </ul>
          </div>
          <div className='line-bottom'>
            <ul>
              <li>
                <a 
                  target='_blank'
                  title='LinkedIn' 
                  rel='noopener noreferrer'
                  href='https://www.linkedin.com/in/hugoelizandro/' 
                >
                  <span className='icon-linkedin' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
