import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../header'
import Footer from '../footer'
import './styles.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet>
          <html lang='en-US' />
          <title>{data.site.siteMetadata.title}</title>
          <meta name='description' content='Hugo Elizandro - Front End Developer based in Florianopolis / SC / Brazil' />
          <meta name='keywords' content='hugo elizandro, front end, frontend, florianopolis, floripa, santa catarina, sc, brazil, freelancer, programmer, programador, developer, desenvolvedor, design' />
          <meta property='og:description' content='Hugo Elizandro - Front End Developer based in Florianopolis / SC / Brazil' />
          <meta property='og:image' content='https://hugo-elizandro.firebaseapp.com/opengraph/opengraph.jpg' />
          <meta property='og:site_name' content='Hugo Elizandro' />
          <meta property='og:title' content='Hugo Elizandro - Front End Developer based in Florianopolis / SC / Brazil' />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://www.hugoelizandro.com/' />
        </Helmet>
        <Header />
        <div>
          {children}
        </div>
        <Footer />
      </Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
